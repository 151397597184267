import React from 'react';
import { Shield } from 'lucide-react';

const CyberShield = () => (
  <div className="relative w-64 h-64 mx-auto my-12">
    <div className="absolute inset-0 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full animate-pulse"></div>
    <div className="absolute inset-2 bg-gray-900 rounded-full flex items-center justify-center">
      <Shield size={80} className="text-cyan-400" />
    </div>
  </div>
);

export default CyberShield;
