import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import axios from 'axios';

const EarlyAccess = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      const response = await axios.post('https://aiopensec.com/api/early-access', { email });
      setMessage(response.data.message);
      setEmail('');
    } catch (error) {
      setMessage(error.response?.data?.message || 'An error occurred while submitting the request');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black py-20 px-4 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="max-w-4xl mx-auto"
      >
        <Link to="/" className="text-cyan-400 hover:text-cyan-300 inline-flex items-center mb-8">
          <ArrowLeft className="mr-2 w-4 h-4" /> Back to Home
        </Link>
        <h1 className="text-4xl sm:text-5xl font-bold mb-8 text-gray-100">Get Early Access</h1>
        <p className="text-xl text-gray-300 mb-8">
          Be among the first to experience the future of AI-powered open-source security. Sign up for our early access program and help shape the future of AIOpenSec.
        </p>
        {message && (
          <div className={`mb-4 p-2 rounded ${message.includes('successfully') ? 'bg-green-500' : 'bg-red-500'}`}>
            {message}
          </div>
        )}
        <form onSubmit={handleSubmit} className="mb-8">
          <div className="flex flex-col sm:flex-row gap-4">
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="flex-grow px-4 py-2 rounded-lg text-gray-900"
              required
            />
            <button
              type="submit"
              className="bg-cyan-500 text-white font-bold py-2 px-6 rounded-lg hover:bg-cyan-600 transition duration-300"
            >
              Join Early Access
            </button>
          </div>
        </form>
        <h2 className="text-2xl font-semibold mb-4 text-gray-100">Early Access Benefits:</h2>
        <ul className="list-disc list-inside text-gray-300 space-y-2">
          <li>Priority access to new features</li>
          <li>Direct influence on product development</li>
          <li>Exclusive webinars and training sessions</li>
          <li>Dedicated support from our team</li>
        </ul>
      </motion.div>
    </div>
  );
};

export default EarlyAccess;
