import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-black bg-opacity-70 py-12">
      <div className="container mx-auto px-4">
        <div className="text-center">
          <p className="text-gray-400">© 2024 AIOpenSec. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
