import React from 'react';
import { motion } from 'framer-motion';
import { Shield, AlertTriangle, TrendingUp, Zap, Link as LinkIcon, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const ThreatCard = ({ threat, index }) => {
  const icons = {
    vulnerability: Shield,
    trend: TrendingUp,
    attack: Zap,
  };
  const Icon = icons[threat.type] || AlertTriangle;

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="bg-gray-800 rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300"
    >
      <div className="flex items-center mb-4">
        <Icon className="w-8 h-8 mr-3 text-cyan-400" />
        <h3 className="text-xl font-bold text-gray-100">{threat.title}</h3>
      </div>
      <p className="text-gray-300 mb-4">{threat.description}</p>
      {threat.cve && (
        <span className="inline-block bg-red-500 text-white text-sm font-semibold px-2 py-1 rounded mb-4">
          {threat.cve}
        </span>
      )}
      <a
        href={threat.source}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-flex items-center text-cyan-400 hover:text-cyan-300"
      >
        <LinkIcon className="w-4 h-4 mr-2" />
        Source
      </a>
    </motion.div>
  );
};

const BackToHomeButton = () => (
  <Link
    to="/"
    className="inline-flex items-center px-4 py-2 bg-gray-800 text-cyan-400 rounded-lg hover:bg-gray-700 transition duration-300 mb-6"
  >
    <ArrowLeft className="w-5 h-5 mr-2" />
    Back to Home
  </Link>
);

const ThreatFeed = () => {
  const threats = [
    {
      title: "PKfail Firmware Vulnerability",
      description: "A firmware vulnerability that bypasses Secure Boot, allowing malware installation. Affects devices including medical equipment and enterprise servers.",
      type: "vulnerability",
      cve: "CVE-2024-8105",
      source: "https://binarly.io/blog/posts/binarly-researchers-discover-a-new-class-of-supply-chain-vulnerabilities"
    },
    {
      title: "Ivanti Connect Command Injection",
      description: "A critical command injection vulnerability allowing remote code execution in Ivanti Connect.",
      type: "vulnerability",
      cve: "CVE-2024-21887",
      source: "https://blog.qualys.com/securitylabs/2024/09/20/2024-midyear-threat-landscape-review"
    },
    {
      title: "Microsoft Windows Security Feature Bypass",
      description: "This bypass vulnerability allows attackers to evade Windows security features.",
      type: "vulnerability",
      cve: "CVE-2024-21412",
      source: "https://blog.qualys.com/securitylabs/2024/09/20/2024-midyear-threat-landscape-review"
    },
    {
      title: "Quantum Computing Risks to Cryptography",
      description: "Quantum computing poses a threat to traditional cryptographic systems, necessitating a shift to quantum-safe encryption.",
      type: "trend",
      source: "https://techbullion.com/cybersecurity-trends-in-2024-what-it-consultants-need-to-know"
    },
    {
      title: "Cloud Intrusions Surge",
      description: "A 75% rise in cloud intrusions, with attackers leveraging stolen credentials to mimic legitimate access.",
      type: "trend",
      source: "https://crowdstrike.com/global-threat-report"
    },
    {
      title: "Generative AI-Driven Cyberattacks",
      description: "AI is being used to automate phishing and malware attacks, making them more sophisticated.",
      type: "attack",
      source: "https://techbullion.com/cybersecurity-trends-in-2024-what-it-consultants-need-to-know"
    },
    {
      title: "Cisco NX-OS CLI Command Injection",
      description: "This vulnerability allows attackers to remotely execute code via CLI on Cisco NX-OS.",
      type: "vulnerability",
      cve: "CVE-2024-20399",
      source: "https://blog.qualys.com/securitylabs/2024/09/20/2024-midyear-threat-landscape-review"
    },
    {
      title: "Fortinet FortiOS Out-of-Bound Write",
      description: "An out-of-bounds write vulnerability in FortiOS that enables remote code execution.",
      type: "vulnerability",
      cve: "CVE-2024-21762",
      source: "https://blog.qualys.com/securitylabs/2024/09/20/2024-midyear-threat-landscape-review"
    },
    {
      title: "Supply Chain Attacks on IT Vendors",
      description: "Attackers are targeting vendor-client relationships, compromising multiple organizations through software supply chain attacks.",
      type: "attack",
      source: "https://crowdstrike.com/global-threat-report"
    },
    {
      title: "Zero-Day Vulnerabilities in Public-Facing Applications",
      description: "Public-facing applications are heavily targeted, with nearly 50% of recent CVEs exploiting such applications.",
      type: "vulnerability",
      source: "https://blog.qualys.com/securitylabs/2024/09/20/2024-midyear-threat-landscape-review"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <BackToHomeButton />
        <h1 className="text-4xl font-bold text-center text-gray-100 mb-12">Latest Cybersecurity Threats and Trends</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {threats.map((threat, index) => (
            <ThreatCard key={index} threat={threat} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ThreatFeed;
