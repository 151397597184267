import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'lucide-react';

const BlogPost = ({ title, date, excerpt }) => (
  <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-6">
    <h2 className="text-2xl font-semibold mb-2 text-gray-100">{title}</h2>
    <p className="text-gray-400 mb-4">{date}</p>
    <p className="text-gray-300 mb-4">{excerpt}</p>
    <Link to="/blog/post" className="text-cyan-400 hover:text-cyan-300 inline-flex items-center">
      Read More <ArrowRight className="ml-2 w-4 h-4" />
    </Link>
  </div>
);

const Blog = () => {
  const blogPosts = [
    {
      title: "The Future of AI in Cybersecurity",
      date: "June 15, 2023",
      excerpt: "Explore how artificial intelligence is revolutionizing the cybersecurity landscape and what it means for businesses of all sizes."
    },
    {
      title: "Open-Source Tools Every SME Should Know",
      date: "June 1, 2023",
      excerpt: "Discover the top open-source security tools that can help small and medium-sized enterprises enhance their cybersecurity posture without breaking the bank."
    },
    {
      title: "Implementing Zero Trust on a Budget",
      date: "May 20, 2023",
      excerpt: "Learn how to implement a Zero Trust security model in your organization, even with limited resources and budget constraints."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black py-20 px-4 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="max-w-4xl mx-auto"
      >
        <Link to="/" className="text-cyan-400 hover:text-cyan-300 inline-flex items-center mb-8">
          <ArrowLeft className="mr-2 w-4 h-4" /> Back to Home
        </Link>
        <h1 className="text-4xl sm:text-5xl font-bold mb-8 text-gray-100">AIOpenSec Blog</h1>
        <p className="text-xl text-gray-300 mb-8">
          Stay informed about the latest trends, insights, and best practices in AI-driven open-source security.
        </p>
        {blogPosts.map((post, index) => (
          <BlogPost key={index} {...post} />
        ))}
      </motion.div>
    </div>
  );
};

export default Blog;
