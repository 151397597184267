import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const Vision = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black py-20 px-4 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="max-w-4xl mx-auto"
      >
        <Link to="/" className="text-cyan-400 hover:text-cyan-300 inline-flex items-center mb-8">
          <ArrowLeft className="mr-2 w-4 h-4" /> Back to Home
        </Link>
        <h1 className="text-4xl sm:text-5xl font-bold mb-8 text-gray-100">Our Vision</h1>
        <p className="text-xl text-gray-300 mb-8">
          At AIOpenSec, we envision a future where robust, AI-driven cybersecurity is accessible to businesses of all sizes. Our mission is to democratize advanced security tools by leveraging the power of open-source technologies and artificial intelligence.
        </p>
        <h2 className="text-2xl font-semibold mb-4 text-gray-100">Key Objectives:</h2>
        <ul className="list-disc list-inside text-gray-300 space-y-2 mb-8">
          <li>Make enterprise-grade security accessible to SMEs</li>
          <li>Advance the integration of AI in cybersecurity practices</li>
          <li>Foster a community-driven approach to security innovation</li>
          <li>Simplify compliance and risk management for businesses</li>
        </ul>
        <p className="text-xl text-gray-300">
          Join us in shaping a more secure digital future for all.
        </p>
      </motion.div>
    </div>
  );
};

export default Vision;
