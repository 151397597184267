import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowLeft, Calendar } from 'lucide-react';
import axios from 'axios';

const Demo = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      const response = await axios.post('https://aiopensec.com/api/demo-request', { name, email, company });
      setMessage(response.data.message);
      // Reset form
      setName('');
      setEmail('');
      setCompany('');
    } catch (error) {
      setMessage(error.response?.data?.message || 'An error occurred while submitting the request');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black py-20 px-4 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="max-w-4xl mx-auto"
      >
        <Link to="/" className="text-cyan-400 hover:text-cyan-300 inline-flex items-center mb-8">
          <ArrowLeft className="mr-2 w-4 h-4" /> Back to Home
        </Link>
        <h1 className="text-4xl sm:text-5xl font-bold mb-8 text-gray-100">Request a Demo</h1>
        <p className="text-xl text-gray-300 mb-8">
          Experience the power of AIOpenSec firsthand. Schedule a personalized demo to see how our AI-driven, open-source security platform can protect your business.
        </p>
        {message && (
          <div className={`mb-4 p-2 rounded ${message.includes('successfully') ? 'bg-green-500' : 'bg-red-500'}`}>
            {message}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4 mb-8">
          <input
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full px-4 py-2 rounded-lg text-gray-900"
            required
          />
          <input
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-2 rounded-lg text-gray-900"
            required
          />
          <input
            type="text"
            placeholder="Company Name"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            className="w-full px-4 py-2 rounded-lg text-gray-900"
            required
          />
          <button
            type="submit"
            className="w-full bg-cyan-500 text-white font-bold py-2 px-6 rounded-lg hover:bg-cyan-600 transition duration-300 flex items-center justify-center"
          >
            <Calendar className="mr-2 w-5 h-5" /> Schedule Demo
          </button>
        </form>
        <p className="text-gray-300">
          Our team will contact you shortly to arrange a demo at a time that suits you best.
        </p>
      </motion.div>
    </div>
  );
};

export default Demo;
