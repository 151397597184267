import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Shield, Eye, Zap, ArrowRight, Calendar, MessageSquare, AlertTriangle, TrendingUp } from 'lucide-react';
import { Link } from 'react-router-dom';
import CyberShield from '../components/CyberShield';
import axios from 'axios';

const FeatureNode = ({ title, description, icon: Icon, color, subfeatures }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4">
      <motion.div
        className={`bg-gray-800 p-4 rounded-lg shadow-lg cursor-pointer flex items-center justify-between ${isOpen ? 'mb-2' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <div className="flex items-center">
          <Icon className={`${color} w-6 h-6 mr-3`} />
          <div>
            <h3 className="text-lg font-semibold">{title}</h3>
            <p className="text-sm text-gray-400">{description}</p>
          </div>
        </div>
        {isOpen ? (
          <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        ) : (
          <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        )}
      </motion.div>
      {isOpen && (
        <motion.ul
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          className="ml-8 border-l-2 border-gray-700"
        >
          {subfeatures.map((subfeature, index) => (
            <motion.li
              key={index}
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className="mb-2 p-2 bg-gray-800 rounded-lg"
            >
              <span className={`${color} mr-2`}>•</span>
              {subfeature}
            </motion.li>
          ))}
        </motion.ul>
      )}
    </div>
  );
};

const Home = () => {
  const [earlyAccessEmail, setEarlyAccessEmail] = useState('');
  const [message, setMessage] = useState('');

  const features = [
    {
      title: "Adapt",
      description: "AI-driven security awareness and dynamic policy management",
      icon: Shield,
      color: "text-cyan-400",
      subfeatures: [
        "Personalized AI-driven security training",
        "Cost-effective phishing defense with behavioral analysis",
        "Simplified insider threat detection",
        "Intelligent policy adjustments",
        "AI-powered user behavior analytics"
      ]
    },
    {
      title: "Anticipate",
      description: "Proactive risk management and automated compliance",
      icon: Eye,
      color: "text-purple-400",
      subfeatures: [
        "AI-driven threat intelligence",
        "Automated multi-framework compliance",
        "Continuous vulnerability scanning",
        "Streamlined third-party risk assessment",
        "AI-powered data privacy management"
      ]
    },
    {
      title: "Accelerate",
      description: "Advanced security tech for rapid response and protection",
      icon: Zap,
      color: "text-pink-400",
      subfeatures: [
        "AI-powered rapid incident response",
        "Simplified AI-assisted forensics",
        "DevSecOps integration",
        "AI-driven Zero Trust implementation",
        "Next-gen AI cloud security"
      ]
    }
  ];

  const handleEarlyAccessSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      const response = await axios.post('https://aiopensec.com/api/early-access', { email: earlyAccessEmail });
      setMessage(response.data.message);
      setEarlyAccessEmail('');
    } catch (error) {
      setMessage(error.response?.data?.message || 'An error occurred while submitting the request');
    }
  };

 const topThreats = [
    {
      title: "PKfail Firmware Vulnerability",
      description: "A firmware vulnerability that bypasses Secure Boot, allowing malware installation.",
      type: "alert",
      icon: AlertTriangle,
      date: "October 2, 2024",
      cve: "CVE-2024-8105"
    },
    {
      title: "Ivanti Connect Command Injection",
      description: "A critical command injection vulnerability allowing remote code execution in Ivanti Connect.",
      type: "alert",
      icon: AlertTriangle,
      date: "September 30, 2024",
      cve: "CVE-2024-21887"
    },
    {
      title: "Quantum Computing Risks to Cryptography",
      description: "Quantum computing poses a threat to traditional cryptographic systems.",
      type: "trend",
      icon: TrendingUp,
      date: "September 28, 2024"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black">
      {/* Hero Section */}
      <header className="py-20 px-4 sm:px-6 lg:px-8 text-center">
        <motion.h1
          className="text-6xl sm:text-7xl font-extrabold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-cyan-400 to-blue-500"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          AIOpenSec
        </motion.h1>
        <motion.p
          className="text-2xl sm:text-3xl mb-6 text-gray-300"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          AI-Powered Open-Source Security for Everyone
        </motion.p>
        <motion.p
          className="text-xl mb-12 text-gray-400"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          Unleash the power of open-source tools and AI to secure your business.
        </motion.p>
        <div className="flex justify-center space-x-4">
          <Link
            to="/early-access"
            className="bg-gradient-to-r from-cyan-500 to-blue-500 text-white font-bold py-3 px-8 rounded-full text-lg hover:from-cyan-600 hover:to-blue-600 transition duration-300 transform hover:scale-105 shadow-lg"
          >
            Get Early Access
          </Link>
          <Link
            to="/demo"
            className="bg-gray-700 text-white font-bold py-3 px-8 rounded-full text-lg hover:bg-gray-600 transition duration-300 transform hover:scale-105 shadow-lg"
          >
            Request Demo
          </Link>
        </div>
        <CyberShield className="mt-12 max-w-md mx-auto" />
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-24">
        {/* Visionary Statement */}
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="mb-20 text-center"
        >
          <h2 className="text-3xl font-bold mb-8 text-gray-100">Join Us in Shaping the Future of Open-Source Security</h2>
          <p className="text-xl text-gray-300 mb-8">
            At AIOpenSec, we're on a mission to democratize security through AI and open-source tools. 
            By 2025, 80% of enterprises will use AI-driven security solutions. Be at the forefront of this revolution.
          </p>
          <Link
            to="/vision"
            className="text-cyan-400 hover:text-cyan-300 inline-flex items-center"
          >
            Learn More About Our Vision <ArrowRight className="ml-2 w-4 h-4" />
          </Link>
        </motion.section>

        {/* Early Access Program */}
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="mb-20"
        >
          <h2 className="text-3xl font-bold mb-8 text-center text-gray-100">Be the First to Experience AI-Powered Open Security</h2>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-2xl mx-auto">
            <p className="text-gray-300 mb-4">
              Join our early access program and shape the future of AIOpenSec. Enjoy priority support, influence feature development, and get a head start on securing your business.
            </p>
            {message && (
              <div className={`mb-4 p-2 rounded ${message.includes('successfully') ? 'bg-green-500' : 'bg-red-500'}`}>
                {message}
              </div>
            )}
            <form onSubmit={handleEarlyAccessSubmit} className="flex flex-col sm:flex-row gap-4">
              <input
                type="email"
                placeholder="Enter your email"
                value={earlyAccessEmail}
                onChange={(e) => setEarlyAccessEmail(e.target.value)}
                className="flex-grow px-4 py-2 rounded-lg text-gray-900"
                required
              />
              <button
                type="submit"
                className="bg-cyan-500 text-white font-bold py-2 px-6 rounded-lg hover:bg-cyan-600 transition duration-300"
              >
                Join Early Access
              </button>
            </form>
          </div>
        </motion.section>

        {/* Interactive Demos/Walkthroughs */}
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.8 }}
          className="mb-20"
        >
          <h2 className="text-3xl font-bold mb-8 text-center text-gray-100">See How AIOpenSec Works</h2>
          {/* Placeholder for AAAFrameworkExplorer */}
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-center">
            <p className="text-gray-300">Interactive AAA Framework Explorer coming soon!</p>
          </div>
        </motion.section>

        {/* Open-Source Community Highlight */}
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1 }}
          className="mb-20 text-center"
        >
          <h2 className="text-3xl font-bold mb-8 text-gray-100">Powered by the Best in Open-Source Security</h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6 max-w-3xl mx-auto">
            {['Wazuh', 'OpenSCAP', 'Osquery', 'TheHive', 'Velociraptor', 'OpenVAS'].map((tool) => (
              <div key={tool} className="bg-gray-800 p-4 rounded-lg shadow-lg">
                <h3 className="text-lg font-semibold text-gray-100">{tool}</h3>
              </div>
            ))}
          </div>
          <p className="text-gray-300 mt-8">
            AIOpenSec leverages these powerful open-source tools to create a seamless, integrated security solution.
          </p>
        </motion.section>

        {/* Why AIOpenSec? */}
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1.2 }}
          className="mb-20"
        >
          <h2 className="text-3xl font-bold mb-8 text-center text-gray-100">Why Choose AIOpenSec?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {features.map((feature, index) => (
              <FeatureNode
                key={index}
                title={feature.title}
                description={feature.description}
                icon={feature.icon}
                color={feature.color}
                subfeatures={feature.subfeatures}
              />
            ))}
          </div>
        </motion.section>

{/*new threat section*/}

<motion.section
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  transition={{ duration: 0.5, delay: 1.4 }}
  className="mb-20"
>
  <h2 className="text-3xl font-bold mb-8 text-center text-gray-100">Latest Threat Alerts and Insights</h2>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
    {topThreats.map((threat, index) => (
      <div key={index} className="bg-gray-800 p-6 rounded-lg shadow-lg">
        <div className="flex items-center mb-4">
          <threat.icon className={`w-6 h-6 mr-2 ${threat.type === 'alert' ? 'text-red-400' : 'text-yellow-400'}`} />
          <span className={`text-sm font-semibold ${threat.type === 'alert' ? 'text-red-400' : 'text-yellow-400'}`}>
            {threat.type.toUpperCase()}
          </span>
        </div>
        <h3 className="text-xl font-semibold mb-2 text-gray-100">{threat.title}</h3>
        <p className="text-gray-300 mb-2">{threat.description}</p>
        <p className="text-gray-400 mb-4">{threat.date}</p>
        {threat.cve && (
          <span className="inline-block bg-red-500 text-white text-sm font-semibold px-2 py-1 rounded mb-4">
            {threat.cve}
          </span>
        )}
        <Link to="/threat-feed" className="text-cyan-400 hover:text-cyan-300 inline-flex items-center">
          Read More <ArrowRight className="ml-2 w-4 h-4" />
        </Link>
      </div>
    ))}
  </div>
  <div className="text-center mt-8">
    <Link to="/threat-feed" className="text-cyan-400 hover:text-cyan-300 inline-flex items-center text-lg">
      View All Threat Alerts <ArrowRight className="ml-2 w-5 h-5" />
    </Link>
  </div>
</motion.section>

        {/* Call to Action */}
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1.6 }}
          className="text-center"
        >
          <h2 className="text-3xl font-bold mb-8 text-gray-100">Ready to Secure Your Digital Assets?</h2>
          <div className="flex justify-center space-x-4">
            <Link
              to="/early-access"
              className="bg-gradient-to-r from-cyan-500 to-blue-500 text-white font-bold py-3 px-8 rounded-full text-lg hover:from-cyan-600 hover:to-blue-600 transition duration-300 transform hover:scale-105 shadow-lg"
            >
              Get Early Access
            </Link>
            <Link
              to="/demo"
	className="bg-gray-700 text-white font-bold py-3 px-8 rounded-full text-lg hover:bg-gray-600 transition duration-300 transform hover:scale-105 shadow-lg flex items-center"
            >
              <Calendar className="mr-2 w-5 h-5" /> Schedule a Demo
            </Link>
          </div>
        </motion.section>
      </main>

      {/* Chat Button */}
      <div className="fixed bottom-8 right-8">
        <button className="bg-cyan-500 text-white p-4 rounded-full shadow-lg hover:bg-cyan-600 transition duration-300">
          <MessageSquare className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default Home;
