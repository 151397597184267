import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Vision from './pages/Vision';
import EarlyAccess from './pages/EarlyAccess';
import Demo from './pages/Demo';
import Blog from './pages/Blog';
import AnimatedBackground from './components/AnimatedBackground';
import ThreatFeed from './components/ThreatFeed';

function App() {
  return (
    <Router>
      <div className="min-h-screen text-white relative overflow-hidden">
        <AnimatedBackground />
        <div className="relative z-20">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/vision" element={<Vision />} />
            <Route path="/early-access" element={<EarlyAccess />} />
            <Route path="/demo" element={<Demo />} />
	    <Route path="/threat-feed" element={<ThreatFeed />} />
            <Route path="/blog" element={<Blog />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
