import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="bg-black bg-opacity-50 py-4">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold text-cyan-400">AIOpenSec</Link>
        {/* Navigation links removed as they're not present in the home page design */}
      </div>
    </header>
  );
};

export default Header;
